import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, selectAll, reducer, selectEntities } from './employee.reducer';
export { State, reducer };

export const getEmployees = createFeatureSelector<State>('employees');
export const getAllEmployees = createSelector(getEmployees, (state) => state ? selectAll(state) : []);
export const getEmployeesDictonery = createSelector(getEmployees, (state) => state ? selectEntities(state) : []);
export const isLoading = createSelector(getEmployees, (state) => state.loading);
// @ts-ignore
export const getEmployee = createSelector(getEmployeesDictonery, (dictionary, props) => dictionary[props.employeeId]);
