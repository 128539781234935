import { Component, OnInit, Input } from '@angular/core';
import { Employee } from 'src/app/employee/employee.model';

@Component({
  selector: 'app-users-selections',
  templateUrl: './users-selections.component.html',
  styleUrls: ['./users-selections.component.css']
})
export class UsersSelectionsComponent implements OnInit {

  constructor() { }
  ngOnInit() {
  }
  @Input() singleSelect: boolean = false;
  // @ts-ignore
  @Input() selection;
  _employees: Employee[] = [];
  @Input() set employees(employees: Employee[] | any) {
    this._employees = employees;
    this.selectAllEmployees();
  }
  get employees(): Employee[] {
    return this._employees;
  }
  selectAllEmployees() {
    this.selection.clear();
    if (this.singleSelect) {
      this.selection.select(this.employees[0]._id);
    } else {
      this.selection.select(...this.employees.map(e => e._id));
    }
  }
  selectEmployee(employee: Employee) {
    this.selection.clear();
    this.selection.select(employee._id);
  }

}
