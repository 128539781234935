import { Action } from '@ngrx/store';
import { Agreement } from '../../companies/models/company.model';
import { HealthInsuranceCompany } from '../models/insurer.model';

export enum AgreementActionsTypes {
    setAgreements = 'set-agreements',
	updateAgreement = 'update-agreement',
	setSelectedAgreement = 'set-selected-agreement',
	updateAgreements = 'update-agreements',
	setPrivateAgreements = 'set-private-agreements',
	updatePrivateAgreement = 'update-private-agreement',
	setSelectedPrivateAgreement = 'set-selected-private-agreement',
	updatePrivateAgreements = 'update-private-agreements',
	setTravelAgreements = 'set-travel-agreements',
	setSelectedTravelAgreement = 'set-selected-travel-agreement',
	updateTravelAgreements = 'update-travel-agreements',
	updateTravelAgreement = 'update-travel-agreement',
	setInsurers = 'set-insurers'
}

export class SetSelectedAgreement implements Action {
	readonly type = AgreementActionsTypes.setSelectedAgreement;
	constructor(public payload: Agreement) { }
}

export class SetAgreements implements Action {
	readonly type = AgreementActionsTypes.setAgreements;
	constructor(public payload: Agreement[]) { }
}

export class UpdateAgreement implements Action {
	readonly type = AgreementActionsTypes.updateAgreement;
	constructor(public payload: Agreement) { }
}

export class UpdateAgreements implements Action {
	readonly type = AgreementActionsTypes.updateAgreements;
	constructor() { }
}

export class SetSelectedPrivateAgreement implements Action {
	readonly type = AgreementActionsTypes.setSelectedPrivateAgreement;
	constructor(public payload: Agreement) { }
}

export class SetPrivateAgreements implements Action {
	readonly type = AgreementActionsTypes.setPrivateAgreements;
	constructor(public payload: Agreement[]) { }
}

export class SetSelectedTravelAgreement implements Action {
	readonly type = AgreementActionsTypes.setSelectedTravelAgreement;
	constructor(public payload: Agreement) { }
}

export class SetTravelAgreements implements Action {
	readonly type = AgreementActionsTypes.setTravelAgreements;
	constructor(public payload: Agreement[]) { }
}

export class UpdateTravelAgreements implements Action {
	readonly type = AgreementActionsTypes.updateTravelAgreements;
	constructor() { }
}

export class UpdateTravelAgreement implements Action {
	readonly type = AgreementActionsTypes.updateTravelAgreement;
	constructor(public payload: Agreement) { }
}

export class UpdatePrivateAgreement implements Action {
	readonly type = AgreementActionsTypes.updatePrivateAgreement;
	constructor(public payload: Agreement) { }
}

export class UpdatePrivateAgreements implements Action {
	readonly type = AgreementActionsTypes.updatePrivateAgreements;
	constructor() { }
}

export class SetInsurers implements Action {
	readonly type = AgreementActionsTypes.setInsurers;
	constructor(public payload: HealthInsuranceCompany[]) { }
}

export type AgreementActions = SetAgreements | UpdateAgreement | SetSelectedAgreement | UpdateAgreements |
SetPrivateAgreements | UpdatePrivateAgreement | SetSelectedPrivateAgreement | UpdatePrivateAgreements |
SetSelectedTravelAgreement | SetTravelAgreements | UpdateTravelAgreements | UpdateTravelAgreement | SetInsurers;