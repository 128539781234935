<mat-form-field   [ngClass]="getClasses()">

  <input matInput [formControl]="formControl" (dateInput)="dateChange($event)" [min]="minDate" [max]="maxDate"
    [matDatepicker]="picker" placeholder="{{label}}" readonly (focus)="picker.open()">
  <button mat-button *ngIf="formControl.value" matSuffix mat-icon-button aria-label="Clear" type="button" (click)="resetDate()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker  disabled="false" #picker></mat-datepicker>
  <mat-error *ngIf="formControl.hasError('required')">
    {{label}} is <strong>required</strong>
  </mat-error>
</mat-form-field>