import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FiltersModule } from 'src/app/core/filters/filters.module';
import { SharedModule } from '../shared/shared.module';
import { YesNoDialogComponent } from './defaults/yes-no-dialog/yes-no-dialog.component';
import { LoadingDialogComponent } from './defaults/loading-dialog/loading-dialog.component';
import { NewTripCompleteNowDialogComponent } from '@trip/new-trip/new-trip-layout/new-trip-complete-now-dialog/new-trip-complete-now-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FiltersModule,
		SharedModule,
	],
	declarations: [YesNoDialogComponent, LoadingDialogComponent,NewTripCompleteNowDialogComponent],
	exports: [YesNoDialogComponent, LoadingDialogComponent,NewTripCompleteNowDialogComponent],
	entryComponents: [YesNoDialogComponent, LoadingDialogComponent,NewTripCompleteNowDialogComponent]
})
export class DialogsModule { }
