// @ts-nocheck
import { AgreementActions, AgreementActionsTypes } from './agreements.actions';
import { GridOptions } from 'src/app/core/models/grid/grid-options.model';
import { Agreement } from '../../companies/models/company.model';
import { SortDirection } from 'src/app/core/models/data';
import { HealthInsuranceCompany } from '../models/insurer.model';

export interface AgreementsState extends GridOptions
{
    agreements?: Agreement[];
    selectedAgreement?: Agreement;
    privateAgreements?: Agreement[];
    selectedPrivateAgreement?: Agreement;
    travelAgreements?: Agreement[];
    selectedTravelAgreement?: Agreement;
    insurers?: HealthInsuranceCompany[];
}

export const initialState: AgreementsState = {
    page: 0,
    pageSize: 25,
    sort: {
        active: 'policyType',
        direction: SortDirection.DESCENDING
    }
};

export function reducer(state = initialState, action: AgreementActions): AgreementsState
{
    switch (action.type)
    {
        case AgreementActionsTypes.updateAgreement:
            const agreementIndex = state.agreements.findIndex((agreement: Agreement) =>
                agreement.policyId === action.payload.policyId);
            const updated = { ...state.agreements[agreementIndex], ...action.payload };
            state.agreements[agreementIndex] = updated;
            return {
                ...state, selectedAgreement: updated
            };

        case AgreementActionsTypes.setAgreements:
            return {
                ...state, agreements: action.payload
            };

        case AgreementActionsTypes.setSelectedAgreement:
            return {
                ...state, selectedAgreement: action.payload
            };

        case AgreementActionsTypes.updateAgreements:
            return {
                ...state, agreements: [...state.agreements]
            };

        case AgreementActionsTypes.updatePrivateAgreement:
            const privateAgreementIndex = state.privateAgreements.findIndex((agreement: Agreement) =>
                agreement.policyId === action.payload.policyId);
            const privateUpdated = { ...state.privateAgreements[privateAgreementIndex], ...action.payload };
            state.privateAgreements[privateAgreementIndex] = privateUpdated;
            return {
                ...state, selectedPrivateAgreement: privateUpdated
            };

        case AgreementActionsTypes.setPrivateAgreements:
            return {
                ...state, privateAgreements: action.payload
            };

        case AgreementActionsTypes.setSelectedPrivateAgreement:
            return {
                ...state, selectedPrivateAgreement: action.payload
            };

        case AgreementActionsTypes.updatePrivateAgreements:
            return {
                ...state, privateAgreements: [...state.privateAgreements]
            };

        case AgreementActionsTypes.setTravelAgreements:
            return {
                ...state, travelAgreements: action.payload
            };

        case AgreementActionsTypes.setSelectedTravelAgreement:
            return {
                ...state, selectedTravelAgreement: action.payload
            };

        case AgreementActionsTypes.updateTravelAgreements:
            return {
                ...state, travelAgreements: [...state.travelAgreements]
            };

        case AgreementActionsTypes.updateTravelAgreement:
            // TODO: do this
            return {
                ...state
            };

        case AgreementActionsTypes.setInsurers:
            return {
                ...state, insurers: action.payload
            };

        default: {
            return state;
        }
    }
}