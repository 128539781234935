// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  // api_endpoint: `http://localhost:3000`,
  // api_health_insurance_endpoint: `http://localhost:3010/health-insurance`,
  // api_currencies_endpoint: `http://localhost:3002/currencies`,
  // api_demographics_endpoint: `http://localhost:3003/demographics`,
  // api_aviation_endpoint: `http://localhost:3005/aviation`,

  api_endpoint : "https://new.api.dev.gingerbit.co.il/api",
  api_health_insurance_endpoint : "https://new.api.dev.gingerbit.co.il/api/health-insurance",
  api_currencies_endpoint : "https://new.api.dev.gingerbit.co.il/api/currencies",
  api_demographics_endpoint : "https://new.api.dev.gingerbit.co.il/api/demographics",
  api_aviation_endpoint : "https://new.api.dev.gingerbit.co.il/api/aviation",

  dashboard_endpoint: "https://dashboard.dev.gingerbit.co.il",

  RECAPTCHA_SITE_KEY: "6LdU74AfAAAAALAMPfTNcooiJH0jjI64ASQzFGqz",
};
