import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgreementsState } from './agreements.reducer';

export { reducer, initialState, AgreementsState } from './agreements.reducer';

export const state = createFeatureSelector<AgreementsState>('agreements');

export const getAgreements = createSelector(state, (currentState) => currentState.agreements);
export const getSelectedAgreement = createSelector(state, (currentState) => currentState.selectedAgreement);
export const getPrivateAgreements = createSelector(state, (currentState) => currentState.privateAgreements);
export const getSelectedPrivateAgreement = createSelector(state, (currentState) => currentState.selectedPrivateAgreement);
export const getTravelAgreements = createSelector(state, (currentState) => currentState.travelAgreements);
export const getSelectedTravelAgreement = createSelector(state, (currentState) => currentState.selectedTravelAgreement);
export const getInsurers = createSelector(state, (currentState) => currentState.insurers);
