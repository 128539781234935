import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from '../content/pages/auth/redux';
import * as fromCustomers from '../content/pages/customers/redux';
import * as fromAgreements from '../content/pages/agreements/redux';
import * as fromProduction from '../content/pages/production/redux';
import * as fromCompany from '../content/pages/companies/redux';
import * as fromUsers from '../content/pages/users/redux';

export interface AppState {
	auth: fromAuth.AuthState;
	customersState: fromCustomers.CustomersState;
	agreementsState: fromAgreements.AgreementsState;
	productionState: fromProduction.ProductionState;
	companiesState: fromCompany.CompaniesState;
	usersState: fromUsers.UsersState;
}

export const initialState: AppState = {
	auth: fromAuth.initialState,
	customersState: fromCustomers.initialState,
	agreementsState: fromAgreements.initialState,
	productionState: fromProduction.initialState,
	companiesState: fromCompany.initialState,
	usersState: fromUsers.initialState
};


export const reducer: ActionReducerMap<AppState> | any = {
	auth: fromAuth.reducer,
	customersState: fromCustomers.reducer,
	agreementsState: fromAgreements.reducer,
	productionState: fromProduction.reducer,
	companiesState: fromCompany.reducer,
	usersState: fromUsers.reducer,
};
