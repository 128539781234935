import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IYesNoDialogInput } from '@dialogs/defaults/yes-no-dialog/yes-no-dialog.args';

@Component({
  selector: 'app-new-trip-complete-now-dialog',
  templateUrl: './new-trip-complete-now-dialog.component.html',
  styleUrls: ['./new-trip-complete-now-dialog.component.scss'],
})
export class NewTripCompleteNowDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IYesNoDialogInput) {}
  saveDisplayDialogSelection(dontShowAgain: any) {
    const event = dontShowAgain;
    console.log("new-trip-complete-now-dialog");
    console.log(event);
    
    if (dontShowAgain) {
      localStorage.setItem('completeNowDontShowDialogFlag', 'true');
    } else {
      localStorage.removeItem('completeNowDontShowDialogFlag');
    }
  }
  ngOnInit() {}
}
