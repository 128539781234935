import { Employee } from "@employee/employee.model";
import { AuthActions, AuthActionTypes } from "./Actions";

export interface AuthState {
    currentLoginUser?: Employee;
}


export const initialState: AuthState = {

};

export function reducer(
    state = initialState,
    action: AuthActions
): AuthState {
    switch (action.type) {
        case (AuthActionTypes.SetCurrentLoginUser):{
            return { ...state,currentLoginUser:action.payload }
        }
        default: {
            return state;
        }
    }
}