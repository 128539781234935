import {Sort} from './sort';

export interface ApiRequest {
	page?: number;
	pageSize?: number;
	sort?: Sort[];
	filters?: { [k: string]: any };
}


