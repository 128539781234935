import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersSelectionsComponent } from './users-selections.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
  ],
  declarations: [UsersSelectionsComponent],
  exports: [UsersSelectionsComponent]
})
export class UsersSelectionsModule { }
