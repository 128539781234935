import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-best-seller',
  templateUrl: './best-seller.component.html',
  styleUrls: ['./best-seller.component.scss'],
})
export class BestSellerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
