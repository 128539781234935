import { Injectable } from '@angular/core';

@Injectable()
export class ChartConfigService {
  private alphaValue = 0.5;
  public backgroundColor = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4BC0C0',
    '#9966CC',
    '#FF9900',
    '#0099CC',
    '#FF3366',
    '#33CCCC',
    '#CC6699',
    '#66CC99',
    '#FF6600',
    '#6699CC',
    '#FFCCCC',
    '#66CCCC',
  ];
  public darkenBackgroundColor = this.backgroundColor.map((color) => this.darkenColor(color, 0.15));
  public transparentColors = this.applyAlphaToColors(this.backgroundColor, this.alphaValue);
  public titleFontSize = 16;
  public labelFontSize = 12;

  constructor() {}

  // Darken the colors by amount in % (10% = 0.1)
  private darkenColor(color: string, amount: number) {
    // Parse the color into RGB components
    const rgb = color
      .substring(1)
      .match(/.{2}/g)
      ?.map((component) => parseInt(component, 16));
    if (!rgb || rgb.length !== 3) {
      return color; // Invalid color format, return original color
    }

    // Darken each RGB component
    const darkenedRGB = rgb.map((component) => Math.round(component * (1 - amount)));

    // Convert the RGB components back to hex format
    const darkenedColor = '#' + darkenedRGB.map((component) => component.toString(16).padStart(2, '0')).join('');

    return darkenedColor;
  }

  private applyAlphaToColors(colors: string[], alpha: number): string[] {
    return colors.map((color) => {
      const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      if (rgb) {
        const [, r, g, b] = rgb;
        return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${alpha})`;
      }
      return color; // Return original color if it doesn't match the expected format
    });
  }
}
