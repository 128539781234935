file drop 2
<div class="row background-container">
  <app-users-selections *ngIf="employees.length" [singleSelect]="singleSelect" [selection]="selection" [employees]="employees" class="side col-md-2"></app-users-selections>

  <div [ngClass]="{ files: true, 'col-md-12': employees.length === 1, 'col-md-10': employees.length > 1 }">
    <mat-list>
      <mat-list-item *ngFor="let file of selectedFiles$ | async">
        <mat-icon mat-list-icon [ngSwitch]="file.type">
          <ng-container *ngSwitchCase="'image'"> photo_size_select_actual </ng-container>
          <ng-container *ngSwitchCase="'pdf'"> picture_as_pdf </ng-container>
          <ng-container *ngSwitchCase="'file'"> insert_drive_file </ng-container>
        </mat-icon>
        <h4 mat-line>{{ file.name }}</h4>
        <p mat-line>
          <mat-progress-bar *ngIf="autoUpload && !file.uploaded" mode="indeterminate"></mat-progress-bar>
        </p>
        <button mat-icon-button *ngIf="!autoUpload || file.uploaded">
          <a target="_blank" *ngIf="file.file" [attr.download]="file.name" [attr.href]="createDownloadUrl(file)">
            <mat-icon mat-list-icon>cloud_download</mat-icon>
          </a>
          <a target="_blank" *ngIf="file.downloadUrl" (click)="downloadFile(file.name, file.downloadUrl)">
            <mat-icon mat-list-icon>cloud_download</mat-icon>
          </a>
        </button>
        <button class="cancel" (click)="remove(file)" mat-icon-button>
          <mat-icon mat-list-icon>cancel</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
    <ngx-file-drop (onFileDrop)="dropped($event)" [class.no_files]="((selectedFiles$ | async) || []).length === 0" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      <ng-container *ngIf="((selectedFiles$ | async) || []).length === 0">
        <h2 *ngIf="showAll && !singleSelect">Upload all trip related documents for all employees</h2>
        <h2 *ngIf="showEmployee">Upload all trip related documents for {{ employee ? employee.firstName + ' ' + employee.lastName : '' }}</h2>
        <span>Flight tickets, hotel voucher, conferences, etc</span>

        <br />
        <br />
        <br />
      </ng-container>
      <span>Drop files here </span>
      <input #fileInput type="file" #addFiles (change)="onFilesAdded()" style="display: none" multiple />
      <button mat-button (click)="fileInput.click()">upload</button>
    </ngx-file-drop>
  </div>
  <div *ngIf="loading" class="spinner-background col-sm-12">
    <mat-spinner></mat-spinner>
  </div>
</div>
