import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-finance-stats',
  templateUrl: './finance-stats.component.html',
  styleUrls: ['./finance-stats.component.scss'],
})
export class FinanceStatsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
