import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FilterAbstractComponent } from '../filter.abstract.component';
import { DateAdapter } from '@angular/material/core';

@Component({
	selector: 'app-date-filter',
	templateUrl: './date-filter.component.html',
	styleUrls: ['./date-filter.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DateFilterComponent),
		}
	]
})
export class DateFilterComponent extends FilterAbstractComponent implements OnInit, ControlValueAccessor
{
	@Input()
	formControl = new FormControl();
	@Input()
	set currentDate(date: Date)
	{
		if (JSON.stringify(this.formControl.value) !== JSON.stringify(date))
		{
			this.formControl.setValue(date);
		}
	}
	@Input()
	minDate = new Date(-Infinity);
	@Input()
	maxDate = new Date(+Infinity);

	@Input()
	formFieldClass: string;

	@Output() dateChangeEvent: EventEmitter<Date> = new EventEmitter<Date>();

	constructor(private dateAdapter: DateAdapter<Date>)
	{
		super();
		this.dateAdapter.setLocale('en-GB');
	}

	dateChange(event: any)
	{
		if (!event.value)
		{
			return;
		}
		const newDate = event.value.toDate();
		this.dateChangeEvent.emit(newDate);
		this.currentDate = newDate;
	}

	ngOnInit()
	{
	}

	writeValue(date: Date): void
	{
		this.currentDate = date;
	}

	registerOnChange(fn: any): void
	{
		this.dateChangeEvent.subscribe((date) => fn(date));
	}

	registerOnTouched(fn: any): void
	{
		this.dateChangeEvent.subscribe((date) => fn(date));
	}

	setDisabledState?(isDisabled: boolean): void
	{
		// todo: disable the input;
	}

	getClasses()
	{
		const classesObj: any = {};
		classesObj[this.formFieldClass] = true;
		return classesObj;
	}

	resetDate(): void {
		this.currentDate = new Date();
		this.dateChangeEvent.emit(this.currentDate)
	}
}