import { Observable, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { LayoutConfig } from '../../config/layout';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable()
export class LayoutConfigStorageService {
  constructor(private utils: UtilsService) {}

  saveConfig(layoutConfig: LayoutConfig): void {
    if (layoutConfig != null) {
      // config storage
      localStorage.setItem('layoutConfig', JSON.stringify(layoutConfig));
    }
  }

  getSavedConfig(): Observable<LayoutConfig> | undefined {
    const config: any = localStorage.getItem('layoutConfig');
    try {
      return of(JSON.parse(config));
    } catch (e) {
      return undefined;
    }
  }

  loadConfig(): Observable<LayoutConfig> {
    const savedConfig$ = this.getSavedConfig();

    if (savedConfig$) {
      return savedConfig$.pipe(
        filter((config) => config !== undefined),
        map((config) => Object.assign(new LayoutConfig(), config))
      );
    } else {
      return of(new LayoutConfig()); // Return a default config
    }
  }

  resetConfig(): void {
    localStorage.removeItem('layoutConfig');
  }
}
