import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./content/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import("./content/pages/pages.module").then(m => m.PagesModule),
    canLoad: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'CFO'],
        redirectTo: 'login',
      },
    },
  },
  {
    path: 'companies',
    redirectTo: '/',
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
