import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FilterAbstractComponent } from '../filter.abstract.component';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-select-filter',
	templateUrl: './select-filter.component.html',
	styleUrls: ['./select-filter.component.css']
})
export class SelectFilterComponent extends FilterAbstractComponent implements OnInit {
	selected = new FormControl();
	_values: { key: string, value: string, selected?: boolean }[] = [];
	@Input()
	set values(values: { key: string, value: string, selected?: boolean }[]) {
		this._values = values;
		this.selected = this.bindSelected(values, this.multiple);
	}
	@Input()
	enableNone = false;
	constructor() {
		super();
	}
	_multiple = false;
	@Input() set multiple(isMulti: false) {
		this._multiple = isMulti;
		this.selected = this.bindSelected(this._values, isMulti);
	}
	@Output()
	selectionChange: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();
	ngOnInit() {
	}
	change(event: any) {
		this.selectionChange.emit(event.value);
	}
	private bindSelected(values: { key: string, value: string, selected?: boolean }[], multi: boolean) {
		const selected = values.filter((val) => val.selected).map(x => x.key);
		if (multi) {
			return new FormControl(selected);
		} else {
			return new FormControl(selected[0]);
		}
	}

}
