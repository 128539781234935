import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthenticationModule } from './core/auth/authentication.module';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AclService } from './core/services/acl.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { PageConfigService } from './core/services/page-config.service';
import { UserService } from './core/services/user.service';
import { UtilsService } from './core/services/utils.service';
import { ClassInitService } from './core/services/class-init.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MessengerService } from './core/services/messenger.service';
import { ClipboardService } from './core/services/clipboard.sevice';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { LogsService } from './core/services/logs.service';
import { QuickSearchService } from './core/services/quick-search.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { HeaderService } from './core/services/layout/header.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { DataTableService } from './core/services/datatable.service';
import { StoreModule } from '@ngrx/store';
import * as fromRoot from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { RequestInteceptor } from './core/auth/request.interceptor';
import { DialogsModule } from './dialogs/dialogs.module';
import { EmployeeDetailsDialogComponent } from '@employee/dialogs/employee-details-dialog/employee-details-dialog.component';
import { ImportSuccessComponent } from './content/pages/companies/company-details-layout/company-details-employees/import-success/import-success.component';
import { AgreementDialogComponent } from './content/pages/companies/agreement-dialog/agreement-dialog.component';
import { SaveNewCompanyDialogComponent } from './content/pages/companies/new-company/save-new-company-dialog/save-new-company-dialog.component';
import { ChangePasswordDialogComponent } from './content/pages/auth/change-password-dialog/change-password-dialog.component';
import { UserToHrDialogComponent } from './content/pages/companies/company-details-layout/company-details-employees/user-to-hr-dialog/user-to-hr-dialog.component';
import { ForgotPasswordDialogComponent } from './content/pages/auth/forgot-password-dialog/forgot-password-dialog.component';
import { AirHelpDialogComponent } from './content/pages/airhelp/airhelp-comment-dialog/airhelp-comment-dialog.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ImportReportDialogComponent } from './content/pages/production/import-report-dialog/import-report-dialog.component';
import { AgreementHealthInsuranceDialogComponent } from './content/pages/agreements/agreement-health-insurance-dialog/agreement-health-insurance-dialog.component';
import { ForgotPasswordResetDialogComponent } from './content/pages/auth/forgot-password-reset-dialog/forgot-password-reset-dialog.component';
import { UsersCodeDialogComponent } from './content/pages/users/users-code-dialog/users-code-dialog.component';
import { UserDetailsDialogComponent } from './content/pages/users/user-details-dialog.ts/user-details-dialog.component';
import { UserDeleteDialogComponent } from './content/pages/users/user-delete-dialog/user-delete-dialog.component';
import { ValidateCodeDialogComponent } from './content/pages/auth/validate-code-dialog/validate-code-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { DownloadService } from './core/services/download.service';
import { ChartConfigService } from './core/services/chart-config.service';

@Injectable()
export class DateAdapterSK extends NativeDateAdapter {}

const MOMENT_FORMATS = {
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
  },
};
export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

@NgModule({
  declarations: [AppComponent],
  imports: [
    DialogsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthenticationModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    StoreModule.forRoot(fromRoot.reducer,{
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      name: 'Ginger Admin',
      logOnly: environment.production,
    }),
    MatPasswordStrengthModule.forRoot(),
    EffectsModule.forRoot([]),
    MatSlideToggleModule,
  ],
  providers: [
    ChartConfigService,
    DownloadService,
    AclService,
    LayoutConfigService,
    LayoutConfigStorageService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    UserService,
    UtilsService,
    ClassInitService,
    MessengerService,
    ClipboardService,
    LogsService,
    QuickSearchService,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },

    // template services
    SubheaderService,
    HeaderService,
    MenuHorizontalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInteceptor,
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_FORMATS },
  ],
  entryComponents: [
    UserToHrDialogComponent,
    ChangePasswordDialogComponent,
    SaveNewCompanyDialogComponent,
    ValidateCodeDialogComponent,
    ForgotPasswordDialogComponent,
    AgreementHealthInsuranceDialogComponent,
    ImportSuccessComponent,
    ForgotPasswordResetDialogComponent,
    EmployeeDetailsDialogComponent,
    AirHelpDialogComponent,
    ImportReportDialogComponent,
    AgreementDialogComponent,
    UsersCodeDialogComponent,
    UserDetailsDialogComponent,
    UserDeleteDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    const host = document.location.hostname.split('.');

    if (host[0] === 'localhost') {
    } else if (host[1] === 'gingerbit') {
      environment.api_endpoint = `https://api.gingerbit.co.il/api`;
      environment.api_health_insurance_endpoint = `https://api.gingerbit.co.il/api/health-insurance`;
      environment.api_currencies_endpoint = `https://api.gingerbit.co.il/api/currencies`;
      environment.api_demographics_endpoint = `https://api.gingerbit.co.il/api/demographics`;
      environment.api_aviation_endpoint = `https://api.gingerbit.co.il/api/aviation`;
      environment.dashboard_endpoint = `https://dashboard.gingerbit.co.il`;
    } else {
      // TODO: find solution for test env
      environment.api_endpoint = "https://new.api.dev.gingerbit.co.il/api"
      environment.api_health_insurance_endpoint = `https://new.api.dev.gingerbit.co.il/api/health-insurance`;
      environment.api_currencies_endpoint = `https://new.api.dev.gingerbit.co.il/api/currencies`;
      environment.api_demographics_endpoint = `https://new.api.dev.gingerbit.co.il/api/demographics`;
      environment.api_aviation_endpoint = `https://new.api.dev.gingerbit.co.il/api/aviation`;
    }
  }
}
