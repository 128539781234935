import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-abstract',
  template: '',
})
export class FilterAbstractComponent {
  @Input() required = false;
  @Input() label = '';
}
