import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IYesNoDialogInput } from './yes-no-dialog.args';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IYesNoDialogInput) {
  }

  ngOnInit() {
  }

}
