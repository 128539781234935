// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
					{
						title: 'Companies',
						root: true,
						page: '/companies',

						icon: 'flaticon-suitcase',
					},
					// TODO: return this
					// {
					// 	title: 'Customers',
					// 	root: true,
					// 	page: '/customers',

					// 	icon: 'flaticon-suitcase',
					// },
					{
						title: 'Agreements',
						root: true,
						page: '/agreements',

						icon: 'flaticon-suitcase',
					},
					{
						title: 'AirHelp',
						root: true,
						page: '/airhelp',

						icon: 'flaticon-paper-plane',
					},
					{
						title: 'Expenses',
						root: true,
						page: '/expenses',

						icon: 'flaticon-line-graph',
					},
					{
						title: 'Users',
						root: true,
						page: '/users',

						icon: 'flaticon-user',
					},
					{
						title: 'Communications',
						root: true,
						page: '/communications',

						icon: 'flaticon-user',
					},
					// TODO: return this
					// {
					// 	title: 'Production',
					// 	root: true,
					// 	page: '/production',

					// 	icon: 'flaticon-suitcase',
					// },
				]
			},
			aside: {
				self: {},
				items: [
					{
						title: 'Dashboard',
						desc: 'Some description goes here',
						root: true,
						icon: 'flaticon-line-graph',
						page: '/',
						badge: { type: 'm-badge--danger', value: '2' },
						translate: 'MENU.DASHBOARD'
					},
					{ section: 'Tools' },
					{
						title: 'Layout Builder',
						root: true,
						icon: 'flaticon-settings',
						page: '/builder'
					}
				]
			}
		};
	}
}
