import { Action } from '@ngrx/store';
import { Company } from '../../companies/models/company.model';

export enum CompanyActionsTypes {
    setCompanies = 'set-companies',
	updateCompany = 'update-company',
	setSelectedCompany = 'set-selected-company',
	updateCompanies = 'update-companies'
}

export class SetSelectedCompany implements Action {
	readonly type = CompanyActionsTypes.setSelectedCompany;
	constructor(public payload: Company) { }
}

export class SetCompanies implements Action {
	readonly type = CompanyActionsTypes.setCompanies;
	constructor(public payload: Company[]) { }
}

export class UpdateCompany implements Action {
	readonly type = CompanyActionsTypes.updateCompany;
	constructor(public payload: Company) { }
}

export class UpdateCompanies implements Action {
	readonly type = CompanyActionsTypes.updateCompanies;
	constructor() { }
}

export type CompanyActions = SetCompanies | UpdateCompany | SetSelectedCompany | UpdateCompanies;