// @ts-nocheck
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, forwardRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterAbstractComponent } from '../filter.abstract.component';
import { MdePopoverTrigger } from '@material-extended/mde';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-select-table-filter',
	templateUrl: './select-table-filter.component.html',
	styleUrls: ['./select-table-filter.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => SelectTableFilterComponent),
		}
	]
})
export class SelectTableFilterComponent extends FilterAbstractComponent implements OnInit, ControlValueAccessor
{
    private subscriptions: Subscription[] = [];

	@Input()
	noRecordsFound: 'none' | 'add' = 'none';

	@Input()
	formControl = new FormControl([]);

	@Input()
	formControlProxy: FormControl;

	@Input()
	formFieldClass: string;

	@Input()
    showCreateNew: boolean = false;
    
    @Input()
	clearFromParentEvent: Observable<void>;

	inputControl = new FormControl('');
	matcher: InputStateMatcher;

	@ViewChild('search') search: ElementRef;

	selection = new SelectionModel(false, []);
	_displayedColumns = ['name'];
	@Input() set displayedColumns(columns: string[])
	{
		this._displayedColumns = columns;
		this.displayedColumnsWithSelect = ['select'].concat(this._displayedColumns);
	}
	dynamicName = (new Date()).getTime();
	displayedColumnsWithSelect: string[] = ['select', 'name'];
	constructor() { super(); }
	dataSource = new MatTableDataSource([]);
	sendToUsersWithoutCompanyDataSource = new MatTableDataSource([]);
	_source: any[] = [];
	@Input()
	set source(source: any)
	{
		this._source = (source ? source.map((obj) =>
		{
			const newObj = { ...obj, id: obj.id || obj.name };
			return newObj;
		}) : []) || [];

		this.dataSource = new MatTableDataSource(this._source);
		
		if (this.isEnableSendToUsersWithOutCompany && this._source.length > 0) this.sendToUsersWithoutCompanyDataSource = new MatTableDataSource([this._source[0]]);
		
		this.setSelection(this._selected, this._multiSelection);
	}
	_multiSelection = false;
	@Input() set multiSelection(isMulti: boolean)
	{
		this._multiSelection = isMulti;
		this.setSelection(this._selected, this._multiSelection);
	}
	_selected = [];
	@Input() set selected(selected: any[] | any)
	{
		if (!(selected instanceof Array))
		{
			selected = selected ? [selected] : [];
		}
		if (this._selected.length === selected.length && JSON.stringify(this._selected) === JSON.stringify(selected))
		{
			return;
		}
		this._selected = selected;
		this.setSelection(selected, this._multiSelection, false);
		this.inputControl.setValue(this.selectedToText(this._selected));

	}
	@Input() autoSelect = false;
	@Input() keyToDisplay = 'name';
	@Input() searchEnable = true;
	@Input() isEnableSendToUsersWithOutCompany = false;
	@Output() selectionChange: EventEmitter<any> = new EventEmitter();
	@Output() isSendToUsersWithOutCompanyToggle: EventEmitter<any> = new EventEmitter();
	@Output() addTrigger: EventEmitter<any[]> = new EventEmitter();
	@ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger;
	@ViewChild('search') searchInput: ElementRef;

	isSendToUsersWithOutCompany = false;

	close()
	{
		this.trigger.closePopover();
	}

	open()
	{
		this.trigger.openPopover();
	}

	apply()
	{
		this.selected = this.selection.selected;
		this.selectionChange.emit(this._selected);
		this.close();
	}
	
	toggleAll() {
		if (this.selection.selected.length === this._source.length) this.clear();
		else this._source.forEach(row => {
			if (!this.selection.isSelected(row)) this.selection.toggle(row);
		});
	}

	toggleIsSendToUsersWithOutCompany() {
		if (!this.isEnableSendToUsersWithOutCompany) return;
		this.isSendToUsersWithOutCompany = !this.isSendToUsersWithOutCompany;
		this.isSendToUsersWithOutCompanyToggle.emit();
	}

	clear()
	{
		this.selection.clear();
		this.selected = [];
	}

	ngOnInit()
	{
		this.matcher = new InputStateMatcher(this.formControlProxy ? this.formControlProxy : this.formControl);
		
		if (this.trigger) {
			this.trigger.opened.subscribe(() =>
			{
				this.setSelection(this._selected, this._multiSelection);
				// this is a hack to wait until the popover is been created!!!
				// TODO: find a way to remove this...
				setTimeout(() =>
				{
					if (this.searchInput)
					{
						this.searchInput.nativeElement.focus();
					}
				}, 100);
			});
		}
        
        if (this.clearFromParentEvent) {
            this.subscriptions.push(this.clearFromParentEvent.subscribe(() => this.clear()));
        }
    }
    
    ngOnDestroy()
	{
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	setSelection(selected: any[], multi: boolean, appendSelection: boolean = true)
	{
		if (this.selection.changed)
		{
			this.selection.changed.complete();
		}
		this.selection =
			new SelectionModel(multi, this._source.filter((dataSourceItem) =>
			{
				const result = this.findByKey(selected, dataSourceItem);
				return result > -1
			}
			));

		if (this.autoSelect)
		{
			this.selection.changed.subscribe(() =>
			{
				this.apply();
			});
		}
	}

	findByKey(selected: any[], dataSourceObject): number
	{
		return selected.findIndex(s =>
		{
			return this.findElmentByProp(s, dataSourceObject)
		})
	}

	findElmentByProp(selectedItem, dataSourceObj): boolean
	{
		if (selectedItem.id)
			return selectedItem.id === dataSourceObj.id;
		if (selectedItem._id)
			return selectedItem._id === dataSourceObj._id;
		if (selectedItem.name)
			return selectedItem.name === dataSourceObj.name;
		return false;
	}

	// TODO: if ginger wants to display on input that send to users without company was selected use this function
	selectedToText(_selected: any[]): string
	{
		let text = '';
		if (!_selected || _selected.length === 0)
		{
			text = '';
		} else if (_selected.length > 0)
		{
			text = _selected[0][this.keyToDisplay];
		}
		if (_selected.length > 1)
		{
			text += `( + ${(_selected.length - 1)} )`;
		}
		return text;
	}

	onSearch(text: string)
	{
		this.dataSource.filter = text;
	}

	writeValue(obj: { [key: string]: string }[]): void
	{
		this.selected = obj;
	}

	registerOnChange(fn: any): void
	{
		this.selectionChange.subscribe((data) => fn(data));
	}

	registerOnTouched(fn: any): void
	{
		this.selectionChange.subscribe((data) => fn(data));
	}

	setDisabledState?(isDisabled: boolean): void
	{
		// todo: disable the input;
	}

	add(str: any)
	{
		this.addTrigger.emit(this.selection.selected);
	}

	getClasses()
	{
		let classesObj: any = {}
		classesObj[this.formFieldClass] = true;
		return classesObj;
	}

	onCheckboxChange(row: RowData, event: any): void {
		this.selection.toggle(row);
	}

	isRowSelected(row: RowData): boolean {
	  return this.selection.isSelected(row);
	}
}

/** Error when invalid control is dirty, touched, or submitted. */
export class InputStateMatcher implements ErrorStateMatcher
{
	constructor(public control: FormControl)
	{

	}

	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean
	{
		const isSubmitted = form && form.submitted;
		const isInteractive = form && form.form && (form.form as any).interacted;
		return !!(this.control && this.control.invalid && (this.control.dirty || this.control.touched || control.dirty || control.touched || isSubmitted || isInteractive));
	}
}