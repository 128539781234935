// @ts-nocheck
import { CompanyActions, CompanyActionsTypes } from './companies.actions';
import { GridOptions } from 'src/app/core/models/grid/grid-options.model';
import { Company } from '../../companies/models/company.model';
import { SortDirection } from 'src/app/core/models/data';

export interface CompaniesState extends GridOptions {
  companies?: Company[];
  selectedCompany?: Company;
}

export const initialState: CompaniesState = {
  page: 0,
  pageSize: 25,
  sort: {
    active: 'policyType',
    direction: SortDirection.DESCENDING,
  },
};

export function reducer(state = initialState, action: CompanyActions): CompaniesState {
  switch (action.type) {
    case CompanyActionsTypes.updateCompany:
      const companyIndex = state.companies.findIndex((company: Company) => company._id === action.payload._id);
      const updated = { ...state.companies[companyIndex], ...action.payload };
      state.companies[companyIndex] = updated;
      return {
        ...state,
        selectedCompany: updated,
      };

    case CompanyActionsTypes.setCompanies:
      return {
        ...state,
        companies: action.payload,
      };

    case CompanyActionsTypes.setSelectedCompany:
      return {
        ...state,
        selectedCompany: action.payload,
      };

    case CompanyActionsTypes.updateCompanies:
      return {
        ...state,
        companies: [...state.companies],
      };

    default: {
      return state;
    }
  }
}
