import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
})
export class StatComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
