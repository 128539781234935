import { Action } from '@ngrx/store';
import { Customer } from '../models/customer.model';

export enum CustomerActionsTypes {
    setCustomers = 'set-customers',
	updateCustomer = 'update-customer',
	setSelectedCustomer = 'set-selected-customer',
	updateCustomers = 'update-customers'
}

export class SetSelectedCustomer implements Action {
	readonly type = CustomerActionsTypes.setSelectedCustomer;
	constructor(public payload: Customer) { }
}

export class SetCustomers implements Action {
	readonly type = CustomerActionsTypes.setCustomers;
	constructor(public payload: Customer[]) { }
}

export class UpdateCustomer implements Action {
	readonly type = CustomerActionsTypes.updateCustomer;
	constructor(public payload: Customer) { }
}

export class UpdateCustomers implements Action {
	readonly type = CustomerActionsTypes.updateCustomers;
	constructor() { }
}

export type CustomerActions = SetCustomers | UpdateCustomer | SetSelectedCustomer | UpdateCustomers;