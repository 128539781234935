import { ProductionActions, ProductionActionsTypes } from './production.actions';
import { GridOptions } from 'src/app/core/models/grid/grid-options.model';
import { Production } from '../models/production.model';
import { SortDirection } from 'src/app/core/models/data';

export interface ProductionState extends GridOptions {
    production?: Production[];
    selectedProduction?: Production;
}

export const initialState: ProductionState = {
    page: 0,
    pageSize: 25,
    sort: {
        active: 'type',
        direction: SortDirection.DESCENDING
    }
};

export function reducer(state = initialState, action: ProductionActions): ProductionState
{
    switch (action.type) {
        case ProductionActionsTypes.updateProduction:
            return {
                // @ts-ignore
                ...state, production: [...state.production]
            };

        case ProductionActionsTypes.setProduction:
            return {
                ...state, production: action.payload
            };

        case ProductionActionsTypes.setSelectedProduction:
            return {
                ...state, selectedProduction: action.payload
            };

        default: {
            return state;
        }
    }
}