export interface ApiResponse<T> {
  success: boolean;
  errorCode: number;
  errorMsg: string;
  data: T;
  totalCount: number;
}


export interface ApiResponseReports<T> extends ApiResponse<T> {
  toApprove: number
}

export interface EmployeeResponse {

  "_id": string,
  "firstName": string,
  "lastName": string,
  "birthDate": string,
  "phoneNumber": string,
  "email": string,
  "role": string,
  "status": string,
  "idDetails": {
    "number": string,
    "slipImage": string,
    "_id": string,
    isPassport?: boolean;
  },
  "passport": {
    "number": string,
    "image": string,
    "_id": string,
    "visas": any[]
  },
  "drivingLicense": {
    "number": string,
    "frontImage": string,
    "backImage": string,
    "_id": "5bfe79259e849c18ac8859d4"
  },
  "phoneNumber2": string,
  "zipCode": string,
  "companyId": string,
  "__v": string,
  "createdAt": string,
  "updatedAt": string,
  noDashboardCompany?: boolean;
}
