import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { SelectFilterComponent } from './select-filter/select-filter.component';
import { FilterAbstractComponent } from './filter.abstract.component';
import { SelectTableFilterComponent } from './select-table-filter/select-table-filter.component';

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatMomentDateModule,
	],
	declarations: [DateFilterComponent, SelectFilterComponent, FilterAbstractComponent, SelectTableFilterComponent],
	exports: [DateFilterComponent, SelectFilterComponent, SelectTableFilterComponent]
})
export class FiltersModule { }
