import { Action } from '@ngrx/store';
import { User } from '../models/users.model';

export enum UsersActionsTypes
{
	setUsers = 'set-users'
}

export class SetUsers implements Action
{
	readonly type = UsersActionsTypes.setUsers;
	constructor(public payload: User[]) { }
}

export type UsersActions = SetUsers;