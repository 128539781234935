// @ts-nocheck
import { CustomerActions, CustomerActionsTypes } from './customers.actions';
import { GridOptions } from 'src/app/core/models/grid/grid-options.model';
import { Customer } from '../models/customer.model';
import { SortDirection } from 'src/app/core/models/data';

export interface CustomersState extends GridOptions {
    customers?: Customer[];
    selectedCustomer?: Customer;
}

export const initialState: CustomersState = {
    page: 0,
    pageSize: 25,
    sort: {
        active: 'customerId',
        direction: SortDirection.DESCENDING
    }
};

export function reducer(state = initialState, action: CustomerActions): CustomersState
{
    switch (action.type) {
        case CustomerActionsTypes.updateCustomer:
            const customerIndex = state.customers.findIndex((customer: Customer) => customer.customerId === action.payload.customerId);
            const updated = {...state.customers[customerIndex], ...action.payload};
            state.customers[customerIndex] = updated;
            return {
                ...state, selectedCustomer: updated
            };

        case CustomerActionsTypes.setCustomers:
            return {
                ...state, customers: action.payload
            };

        case CustomerActionsTypes.setSelectedCustomer:
            return {
                ...state, selectedCustomer: action.payload
            };

        case CustomerActionsTypes.updateCustomers:
            return {
                ...state, customers: [...state.customers]
            };

        default: {
            return state;
        }
    }
}