import { GridOptions } from 'src/app/core/models/grid/grid-options.model';
import { SortDirection } from 'src/app/core/models/data';
import { User } from '../models/users.model';
import { UsersActions, UsersActionsTypes } from './users.actions';

export interface UsersState extends GridOptions
{
    users?: User[];
}

export const initialState: UsersState = {
    page: 0,
    pageSize: 25,
    sort: {
        active: 'firstName',
        direction: SortDirection.DESCENDING
    }
};

export function reducer(state = initialState, action: UsersActions): UsersState
{
    switch (action.type)
    {
        case UsersActionsTypes.setUsers:
            return {
                ...state, users: action.payload
            };

        default: {
            return state;
        }
    }
}