canvas 5
<!-- <div class="m-widget14">
	<div class="m-widget14__header">
		<h3 class="m-widget14__title">
			Daily Sales
		</h3>
		<span class="m-widget14__desc">
		Check out each collumn for more details
		</span>
	</div>
	<canvas baseChart
			[data]="doughnutChartData"
			[labels]="doughnutChartLabels"
			[chartType]="doughnutChartType"
			(chartHover)="chartHovered($event)"
			(chartClick)="chartClicked($event)"></canvas>
</div> -->