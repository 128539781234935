import { Employee } from '@employee/employee.model';
import { Action } from '@ngrx/store';

export enum AuthActionTypes {
	 SetCurrentLoginUser='[Auth] Set Current Login User'
}

export class SetCurrentLoginUser implements Action {
	readonly type = AuthActionTypes.SetCurrentLoginUser;

	constructor(public payload?: Employee) { }
}

export type AuthActions = SetCurrentLoginUser;