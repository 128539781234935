import { Action } from '@ngrx/store';
import { Production } from '../models/production.model';

export enum ProductionActionsTypes {
    setProduction = 'set-production',
	setSelectedProduction = 'set-selected-production',
	updateProduction = 'update-production'
}

export class SetSelectedProduction implements Action {
	readonly type = ProductionActionsTypes.setSelectedProduction;
	constructor(public payload: Production) { }
}

export class SetProduction implements Action {
	readonly type = ProductionActionsTypes.setProduction;
	constructor(public payload: Production[]) { }
}

export class UpdateProduction implements Action {
	readonly type = ProductionActionsTypes.updateProduction;
	constructor() { }
}

export type ProductionActions = SetProduction | SetSelectedProduction | UpdateProduction;