<mat-list [style.display]="employees.length > 1 ? 'block' : 'none'">
  <mat-list-item *ngIf="!singleSelect" role="listitem" [class.active]="selection.selected.length === employees.length"
    (click)="selectAllEmployees()">All
    Employees</mat-list-item>
  <ng-container *ngFor="let employee of employees">
    <mat-divider></mat-divider>
    <mat-list-item role="listitem" [class.active]="selection.selected.length === 1 && selection.selected[0] === employee._id"
      (click)="selectEmployee(employee)">{{
      employee.firstName }} {{ employee.lastName }}</mat-list-item>
  </ng-container>
</mat-list>